.SimpleConfirmDialog.SimpleDialog.quote-conversion-dialog {
  .ant-typography {
    &:not(.nl-as-newline) {
      font-size: 24px;
      margin-bottom: 18px;
    }

    &.nl-as-newline {
      font-weight: 500;

      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
}
