#newQuoteForm {
  .NewShipmentPackage-PreviewBody {
    padding-bottom: 20px;
  }
}

.ant-message-notice.quotes-notification .ant-message-notice-content {
  box-shadow: 0 0 40px @colorGrey7_5;

  .ant-message-custom-content {
    padding-right: 14px;
  }

  .anticon-exclamation-circle {
    padding-top: 14px;
    align-self: flex-start;
    margin-right: 0;
  }

  .quotes-notification-content {
    color: @colorBlack;
    font-weight: 500;
    height: auto;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: center;

    > a {
      margin-left: 4px;
    }
  }
}

.pieces-summary {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;

  .pieces-summary-label {
    font-family: @fontCondensed;
    font-size: 17px;
    font-weight: 700;
    color: @colorGreyBluish2;
  }

  .pieces-summary-badge {
    display: flex;
    color: @colorRed;
    font-size: 18px;
    font-weight: 500;
    height: 30px;
    width: 130px;
    background-color: @colorWhite2;
    box-shadow: @colorGrey8_1 1px 1px 6px 0;
    border-radius: 6px;
    text-align: center;
    justify-content: center;
  }
}

.new-quote-package-header {
  border-top: 1px solid @colorGrey7_8;
  border-bottom: 1px solid @colorWhite;
  color: @colorGrey3;
  font-family: @fontCondensed;
  font-size: 17px;
  font-weight: 700;
  height: 30px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.NewQuotePackagesCardBody {
  margin-bottom: 40px;

  .DimensionalWeightResultLabel {
    font-family: @fontCondensed;
    font-weight: 700;
    font-size: 16px;
  }

  .NewShipmentPackagesSummary-Badge {
    width: 130px;
    height: 30px;
  }

  .NewShipmentPackageListForm {
    .fa-plus-square {
      padding-left: 5px;
      padding-right: 10px;
    }

    .quote-packages-inputs {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
    }

    &.has-error {
      .ant-input-number {
        border: 1px solid @colorRed;
      }

      + .NewShipmentPackage-ErrorMessage.has-content {
        padding: 5px 20px;
      }
    }
  }

  .DimensionalWeightResult,
  .DimensionalWeightResultSeparator,
  .NewShipmentPackage-BilledWeight {
    font-size: 16px;
    font-weight: 700;
    line-height: inherit;
  }

  .DimensionalWeightResult,
  .NewShipmentPackage-BilledWeightValue {
    color: @colorRed;
  }

  .DimensionalWeightResultSeparator {
    padding-left: 2px;
    padding-right: 2px;
  }

  .DimensionalWeightResultSeparator,
  .NewShipmentPackage-BilledWeight {
    color: @colorGreyBluish;
  }

  .LabelWithValue .text {
    font-size: 14px;
  }

  .NewShipmentPackage-Title {
    margin-bottom: 12px;
    height: auto;
    line-height: 1.5715;
  }

  .AddOrCopyPackageButtons {
    position: absolute;
    margin-top: 10px;
  }
}

.quotes-form-package-summary {
  justify-content: space-between;
  padding: 5px 20px 10px;
}

.NewQuotePackage-ControlButtonsRow {
  display: flex;
  justify-content: space-between;

  .NewQuotePackage-ControlButtons {
    display: flex;
    align-items: center;

    .IconButton-Icon {
      color: @colorRed;
      padding-left: 5px;
    }
  }
}

.quote-package-weight-result {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 20px;
  margin-bottom: 5px;

  .quote-package-button {
    display: flex;
    align-items: center;

    .fa-times-circle {
      margin-left: 12px;
      font-size: 18px;
      color: @colorRed;
      cursor: pointer;
    }
  }
}

.ant-btn.quotes-calculate-btn {
  height: 24px;
  width: 65px;
  font-size: 11px;
}

.edit-quote-cancel {
  padding-bottom: 12px;
}

.edit-quote-label {
  color: @colorGrey11;
  font-family: Roboto;
  font-weight: 500;
}

.edit-quote-id {
  color: @colorRed;
  font-family: Roboto;
  font-weight: 500;
}

.NewShipmentTopControls.quote-submit-button > .ant-btn-primary[disabled] {
  &,
  &:hover,
  &:active {
    background-color: @colorRed;
    opacity: 0.65;
  }
}
