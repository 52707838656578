.topBar() {
  color: @colorGrey3;
  font-weight: 500;
}

.AccountTopBar {
  height: 25px;
  .topBar();
  font-size: @font-size-sm;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;

  > .left {
    display: flex;
    min-width: 0;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: @spaceNorm2;
    white-space: nowrap;
  }
  > .right {
    width: auto;
  }

  @media (max-width: @screen-sm-max) {
    display: none;
  }

  .DropdownMenu-Label,
  .Popup-Label {
    .fa-caret-down,
    .fa-caret-up {
      font-size: @font-size-lg;
    }
  }

  .active-account {
    padding-right: 4px;
    span {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.UserQuickSetup__Label {
  display: inline-flex;
  align-items: center;
  position: relative;
  line-height: 1;

  > * {
    .space-between(@spaceNorm0_5, horizontal);
  }
}

.UserQuickSetup__DialogWrap.ant-modal-wrap.ant-modal-centered {
  align-items: flex-start;
  padding-top: ~'min(290px, calc((100vh - 800px) / 2))';
}

.UserQuickSetup__Dialog.SimpleDialog.ant-modal {
  max-height: 800px;
  display: flex;
  flex-direction: column-reverse;

  .ant-modal-body {
    position: relative;
    padding: @spaceNorm @spaceNorm2;
    align-items: stretch;

    .UserQuickSetup__SavingMask {
      .absolute(0);
      .mask(90);
      .flex-center();
      z-index: 1;
      font-size: @font-size-lg;
      font-weight: bold;
    }
  }

  .ant-modal-content {
    width: 400px;
    min-height: 0;
    margin-top: @spaceNorm2;
  }

  form {
    margin-bottom: 0;
  }

  .UserQuickSetup__Title {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: @spaceNorm;
    color: @colorGreyDark0_7;
    pointer-events: auto;
  }

  h1.ant-typography {
    line-height: 1;
    font-family: @fontCondensed;
    font-weight: bold;
    letter-spacing: -0.45px;
    text-transform: uppercase;
    margin-bottom: 0;
    color: @colorGreyDark0_7;
  }

  h4.ant-typography {
    font-size: @heading-3_5-size;
    font-weight: 300;
    letter-spacing: -0.24px;
    color: @colorGrey4_5;
    margin-bottom: 0;
    padding-bottom: @spaceNorm0_5;
    border-bottom: 1px solid @colorWhite;
  }
  h4.ant-typography + .TwoLines {
    margin-top: -1px;
  }

  .UserQuickSetup__Row {
    padding: 0 @spaceNorm;
    > .ant-col:first-child {
      flex: 18;
    }
    > .ant-col:last-child {
      flex: 15;
    }
  }
  .UserQuickSetup__Headers {
    margin-top: @spaceSm;
    margin-bottom: @spaceXxs;
    font-family: @fontCondensed;
    font-size: @font-size-sm-plus;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
  }
  .UserQuickSetup__Radios {
    margin-top: @spaceMd;
    font-family: @fontCondensed;
    font-weight: bold;

    @media (max-width: @screen-xs-max) {
      label.ant-radio-wrapper {
        font-size: @font-size-sm-plus;
      }
    }
  }
}

.UserQuickSetup__NotificationsScrollbar {
  max-height: 451px;
}

.UserQuickSetup__SegmentedControl.SegmentedControl {
  height: 40px;
  border-radius: 0;
  background-image: linear-gradient(
    -63deg,
    @colorGrey9_9,
    @colorWhite1_5 48%,
    @colorGrey9_9
  );
  box-shadow: none;

  .both() {
    font-family: @fontStd;
    font-size: @heading-4-size;
    font-weight: 500;
  }
  .std() {
    .both();
    color: @colorGreyBluish;
  }
  .active() {
    .both();
    color: @colorWhite;
  }

  .SegmentedControl-Inner {
    &:hover .SegmentedControl-Segment.active {
      .std();
      &:hover {
        .active();
      }
    }
  }
  .SegmentedControl-Segment {
    .std();
    &.active,
    &:hover {
      .active();
    }
  }

  .SegmentedControl-ActiveIndicator {
    background-image: linear-gradient(
      180deg,
      @colorGreyDark0_5,
      @colorGreyDark0_7
    );
  }
}

.UserQuickSetup__EmailNotifications {
  padding-top: @spaceNorm0_5;
}

.Account-UserLabel {
  display: inline-flex;
  align-items: center;

  @{fa-selector} {
    margin-left: 0;
    margin-right: @spaceXs;
    color: @colorRed;
  }
}

.NonLoggedTopBar {
  height: @spaceNorm3;
  .topBar();
  font-size: @font-size-sm-plus;
  display: flex;
  align-items: center;

  @media (max-width: @screen-lg-max) {
    display: none;
  }
}

.header(@break-lg: @screen-lg, @break-sm: @screen-md) {
  background-color: @colorWhite2;
  border-radius: @borderRadiusMd;
  box-shadow:
    -1px -1px 1px @colorWhite,
    6px 6px 15px rgba(@colorGreyBluish, 0.35);

  > .ant-row {
    height: 100%;
  }

  @media (min-width: @break-lg) {
    height: 80px;
    padding: 0 @spaceNorm2;
  }
  @media (min-width: @break-sm) and (max-width: (@break-lg - 1px)) {
    height: 70px;
    padding: 0 @spaceNorm;
  }
  @media (max-width: (@break-sm - 1px)) {
    height: 50px;
    padding: 0 @spaceNorm;
  }
  @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    padding: 0 @spaceSm;
  }
}

.NonLoggedHeader {
  .header(@break-lg: @screen-xl);
  @media (max-width: @screen-xs-max) {
    margin: -@spaceNorm;
    margin-bottom: 0;
  }

  &,
  & .ant-form-item-label > label {
    color: @colorRed;
    font-weight: bold;
  }

  & .ant-form-item-label > label {
    height: 30px;
  }

  .TrackNumberInput {
    @media (min-width: @screen-md-min) {
      width: 240px;
    }
    @media (max-width: @screen-sm-max) {
      width: 200px;
    }
  }
}
.NonLoggedMenu.LinksRow {
  color: @colorRed;
  font-weight: bold;
  @media (min-width: @screen-xl-min) {
    font-size: @font-size-base;
  }
  @media (max-width: @screen-lg-max) {
    font-size: @font-size-sm;
  }
}

.MainMenuHeader {
  .header();
  border: 1px solid @colorWhite;

  .IconButton {
    display: inline;
  }

  .InputSearchFlatCol {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        width: auto;
        .Flex1();
        margin-left: @spaceNorm2;
      },
      @lg-and-bigger: {
        width: 500px;
      }
    );
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .DropdownMenu-Label {
    display: block;
  }
}

.DetailPageHeader {
  padding: 0 @spaceNorm;
  background-color: @colorWhite2;
  box-shadow:
    -1px -1px 1px @colorWhite,
    6px 6px 15px rgba(@colorGrey9, 0.85);
  border-radius: @borderRadiusSm;

  @media (max-width: @screen-sm-max) {
    padding: 0 @spaceSm;
  }

  > .ant-row {
    height: 100%;
  }

  h2.ant-typography {
    margin-bottom: 0;
    letter-spacing: -0.35px;
    font-family: @fontCondensed;
    color: @colorGrey1_5;

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      font-size: @heading-3-size;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      font-size: @heading-3_5-size;
    }
    @media (max-width: @screen-xs-max) {
      font-size: @heading-4-size;
    }
  }
}

.DetailPageHeader--size-40 {
  height: 40px;
}
.DetailPageHeader--size-50 {
  height: 50px;
}
.DetailPageHeader--WithBackLink {
  padding-right: @spaceNorm2;

  .InlineLink {
    font-size: @font-size-sm;
    font-family: @font4;
    font-weight: 600;
    color: @colorGrey3;
    text-transform: uppercase;

    @media (max-width: @screen-xs-max) {
      font-size: @font-size-xs;
    }

    @{fa-selector} {
      &:first-child {
        font-size: 18px;
        color: @colorRed;
        margin-right: @spaceMd;
      }
    }
  }
}

.QuickOnlineLogo {
  position: relative;
  background-image: url('../resources/img/quick-online-logo.svg');
  background-size: 100% 100%;

  &.size-sm {
    width: 173px;
    height: 50px;
  }

  &.size-md {
    width: 192.5px;
    height: 56px;
  }

  &.size-lg {
    width: 220px;
    height: 64px;

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 173px;
      height: 50px;
    }
  }

  height: 100%;
  > img {
    height: 100%;
    width: auto;
  }

  @media (max-width: @screen-sm-max) {
    &:not(.always-full-image) {
      background-image: url('../resources/img/quick-online-logo-q.svg');
      width: 33px;
      height: 35px;
    }
  }
}

.QuickOnlineLogoQ {
  background-image: url('../resources/img/quick-online-logo-q.svg');
  background-size: 100% 100%;

  &.size-xs {
    width: 21px;
    height: 20px;
  }
  &.size-sm {
    width: 30px;
    height: 28px;
  }
  &.size-md {
    width: 43px;
    height: 45px;

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 33px;
      height: 35px;
    }
  }
  &.size-lg {
    width: 60px;
    height: 58px;

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 38px;
      height: 40px;
    }
  }
}

.Footer {
  height: 24px;
  font-weight: 500;
  .border-radius(@side: bottom);

  &-Dark {
    background-color: @colorGreyBluish;
    color: @colorWhite;
  }
  &-Light {
    background-color: @colorWhite2;
    color: @colorGrey4;
    box-shadow: 2px 4px 12px rgba(@colorGreyBluish, 0.35);
  }
}

.footer-LinksAbove() {
  color: @colorRed;

  > .ThreeLines {
    @media (min-width: @screen-xl-min) {
      margin-bottom: @spaceNorm2;
    }
    @media (max-width: @screen-lg-max) {
      margin-bottom: @spaceNorm;
    }
  }
}

.NonLoggedFooter-LinksAbove {
  .footer-LinksAbove();
  font-weight: bold;

  > .LoggedFooter-Links {
    margin-bottom: @spaceXs;
  }
}
.LoggedFooter-LinksAbove {
  .footer-LinksAbove();

  > .LoggedFooter-Links {
    font-size: @font-size-xs;
    font-weight: 500;
    margin-bottom: @spaceSm;
  }
}

.LoggedFooter-Links {
  .flex-center();

  .DropdownMenu-Label,
  .Popup-Label {
    .fa-caret-down,
    .fa-caret-up {
      font-size: @font-size-base;
    }
  }
}

.AddressRowIcon {
  color: @colorRed;

  @{fa-selector} {
    font-size: 21px;
  }
}

.FreeTextJobSearchIcon {
  font-size: 20px;
}

.MetadataInfo {
  > .ant-col {
    .InlineLink {
      display: inline-flex;
      color: @colorRed;
      text-decoration: underline;
    }
  }
}

.AccountsPicker-widget {
  background-color: @colorGreyDark1_3;
  border: 1px solid @colorGreyDark3;
  box-shadow: 0 4px 30px fade(#1f262e, 50%);
  border-radius: 6px;

  .AccountsPicker-title,
  .AccountsPicker-subtitle {
    line-height: 30px;
    font-family: @fontCondensed;
    color: @colorGreyBluish8;
  }

  .AccountsPicker-title {
    background-color: @colorGreyDark3;
    font-size: 14px;
    font-weight: bold;
    padding: 0 5px;
    border-bottom: 1px solid @colorBlack2;
  }

  .AccountsPicker-subtitle {
    font-size: 13px;
    font-weight: 400;
    background-color: @colorGreyDark2_7;
    padding-left: 20px;
    border-top: 1px solid @colorGrey3_5;
    border-bottom: 1px solid @colorBlack2;
  }

  .AccountsPicker-icon {
    padding: 0 5px 0 10px;
  }

  .AccountsPicker-body {
    padding: 20px;
  }

  .AccountsPicker-item {
    color: @colorOrange3;
    padding: 10px;
    font-family: @fontStd;
    font-size: 14px;
    font-weight: 400;
    background-color: @colorGreyDark3;
    border-radius: 6px;

    & + .AccountsPicker-item {
      margin-top: 20px;
    }
  }

  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-wrapper {
    &,
    &.ant-checkbox-wrapper-checked {
      color: @colorGreyBluish3_2;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .AccountsPicker-edit-header {
    background-color: @colorGreyDark2_7;
    padding: 15px 10px;
    border-top: 1px solid @colorGrey3_5;
    border-bottom: 1px solid @colorBlack2;
  }

  .search-input {
    flex-grow: 1;
    margin-right: 20px;
  }

  .ant-checkbox-wrapper {
    line-height: 40px;
    margin-left: 10px;
  }

  .AccountsPicker-no-items {
    color: @colorGreyBluish3_2;
    padding: 10px;
    border-top: 1px solid @colorGrey3_5;
  }

  .AccountsPicker-edit-body {
    border-top: 1px solid @colorGrey3_5;
  }
}
