@import '../../node_modules/antd/dist/antd.less';
@import 'variables.less';

@import 'shared.less';
@import 'vendorOverrides.less';
@import 'fonts.less';
@import 'utils.less';
@import 'responsive.less';
@import 'animations.less';
@import 'common-classes.less';
@import 'layout.less';
@import 'typography.less';
@import 'widgets.less';
@import 'pages.less';

@import 'components/forms.less';
@import 'components/formElements.less';
@import 'components/tables.less';
@import 'components/Scrollbars.less';
@import 'components/Spinner.less';
@import 'components/buttons.less';
@import 'components/maps.less';
@import 'components/dateElements.less';
@import 'components/selectElements.less';
@import 'components/uploadElements.less';
@import 'components/menus.less';
@import 'components/navElements.less';
@import 'components/MilestoneStepper.less';
@import 'components/layoutElements.less';
@import 'components/scrollElements.less';
@import 'components/cardElements.less';
@import 'components/dialogs.less';
@import 'components/modals.less';
@import 'components/drawers.less';
@import 'components/SlidingContent.less';
@import 'components/SegmentedControl.less';
@import 'components/CircularProgressbar.less';
@import 'components/Gauge.less';
@import 'components/Collapsible.less';
@import 'components/Accordion.less';
@import 'components/templates.less';
@import 'components/dataRenderers.less';
@import 'components/widgets.less';
@import 'components/accountElements.less';
@import 'components/emailNotifications.less';
@import 'components/transfer.less';

@import 'containers/quotes.less';
@import 'containers/NewShipmentPage.less';
@import 'containers/NewQuotePage.less';
@import 'containers/MonitorPage.less';
@import 'containers/shipmentDetails.less';
@import 'containers/NonLoggedPage.less';
@import 'containers/TermsOfServicePage.less';
@import 'containers/changelog.less';
@import 'containers/reports.less';
@import 'containers/savedShipments/ReleaseShipmentPage.less';
@import 'containers/savedShipments/EditShipmentPage.less';
@import 'containers/admin/AdminConsoleTemplate.less';
@import 'containers/admin/AdminConsoleComponents.less';
@import 'containers/admin/AdminConsoleList.less';
@import 'containers/admin/AdminUserList.less';
@import 'containers/admin/AdminUserDetail.less';
@import 'containers/admin/AdminUserForms.less';
@import 'containers/admin/AdminAddUser.less';
@import 'containers/admin/AdminAccountList.less';
@import 'containers/admin/AdminAccountDetail.less';
@import 'containers/LinkDeviceToJobPage.less';
@import 'containers/ViewQuoteBreakdownDialog.less';
@import 'containers/QuoteConversionDialog.less';

* {
  box-sizing: border-box;
}

html {
  font-size: @font-size-base;
}

p {
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus {
  outline: none;
}

input,
select,
textarea {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus {
      // Colors cannot be overriden, but we can make them animate so slow that they will never take effect
      transition:
        background-color 9999s ease-in-out 0s,
        color 9999s ease-in-out 0s;
    }
  }
}

// Reset of antd's selection style
::selection {
  background-color: #2f7fdf !important;
  color: @colorWhite !important;
}
