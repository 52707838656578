.shipment-info-title() {
  font-family: @font6;
  font-size: 24px;
  color: @colorGrey11;
  font-weight: 400;
  margin: 0;
}

.quote-summary-header {
  padding: 0;
  height: 80px;
  background-color: @colorWhite2;
  border-radius: 8px;
  border: 1px solid @colorWhite;
  box-shadow:
    6px 6px 15px rgba(@colorGrey8_7, 0.85),
    -1px -1px 1px @colorWhite;

  .ant-card-body {
    padding: 8px 20px;
  }

  h1 {
    color: @colorGrey3;
    font: 400 48px @font6;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: -8px 0 -2px 0;
  }

  .details-grid {
    .details-grid-item {
      white-space: nowrap;
      display: flex;
      align-items: center;

      &.details-grid-item-first {
        .label {
          min-width: 50px;
        }
      }

      &.details-grid-item-second {
        .label {
          min-width: 80px;
        }
      }

      &.details-grid-item-last {
        .label,
        .details-grid-item-dg-number {
          min-width: 125px;
        }

        .details-grid-item-dg-number {
          color: @colorGrey2;
          min-width: 125px;
        }

        .details-grid-item-dg-desc {
          color: @colorGrey2;
          font-size: 14px;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: 150px;
          overflow: hidden;
        }
      }
    }

    .label {
      color: @colorGreyBluish2;
      font-size: @font-size-sm;
      text-transform: uppercase;
    }

    .value {
      font-size: @font-size-lg-minus;
      color: @colorRed;
      font-weight: 700;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 150px;
      overflow: hidden;
    }
  }

  .InlineLink {
    justify-self: flex-end;
    color: @colorBlue3;
    margin-bottom: -8px;

    > span {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: bold;
    }
  }

  .TwoLinesSeparator {
    margin-top: 4px;
    margin-bottom: 4px;
    &::before {
      border-top: 1px solid @colorGrey9;
      background-color: @colorWhite8;
    }
  }

  .quote-id-label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: -8px;
    color: @colorGrey11;

    .quote-id {
      margin-left: 4px;
      color: @colorRed;
    }
  }
}

.quote-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-radius: 6px;
  box-shadow: 6px 6px 20px #bfc3ca;

  .quote-button {
    padding: 29px 0;
    color: @colorWhite;
    font: 700 28px @fontCondensed;
    text-align: center;
  }

  .save-button,
  .cancel-button {
    display: flex;
    flex-direction: column;
    width: 15%;
    text-align: center;
    color: @colorGrey3;
    font: 500 19px @fontStd;
  }

  .quote-expires {
    font-size: 12px;
  }

  .continue-button {
    width: 70%;
    background-color: @colorRed;
    color: @colorWhite;
    font: 700 28px @fontCondensed;
    text-align: center;
    border-radius: 0;
  }

  .cancel-button {
    width: 15%;
    text-align: center;
    border-radius: 0 6px 6px 0;
  }

  .save-button {
    border-radius: 6px 0 0 6px;
  }
}

.itinerary-card {
  white-space: nowrap;
}

.itinerary-status {
  color: @colorBlue3;
  font-family: @fontCondensed;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 4px;
}

.itinerary-label {
  color: @colorGreyBluish;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: uppercase;

  &--bold {
    font-family: @fontCondensed;
    font-size: 12px;
    font-weight: 700;
    height: 22px;
  }
}

.itinerary-value {
  color: @colorGrey3;
  font-size: 14px;
  font-weight: 500;
}

.notice {
  margin-top: 16px;
  font-size: @font-size-sm;
}

.quote-header {
  height: 24px;
  background-color: @colorGreyBluish;
  padding: 0 @spaceNorm;
  box-shadow: -6px -6px 15px @colorWhite;
  .border-radius(@side: all);
  font-weight: 500;
  color: @colorWhite;

  h4.ant-typography {
    font-size: @font-size-base;
    color: @colorWhite;
    margin-bottom: 0;
  }
}

.shipment-details {
  .quote-header {
    margin-bottom: 4px;
  }
  .two-lines-separator {
    margin: 4px 0;
  }
}

.ShipmentDetails {
  padding-right: 40px;
  color: @colorGrey2;
  font-size: 15px;
  font-weight: 500;

  .QuoteHeader {
    background-color: @colorGreyBluish;
    padding: 3px @spaceNorm;
    box-shadow: -6px -6px 15px @colorWhite;
    .border-radius(@side: all);
    font-weight: 500;
    color: @colorWhite;
    margin-bottom: 12px;

    h4.ant-typography {
      font-size: @font-size-base;
      color: @colorWhite;
      margin-bottom: 0;
    }
  }

  .flight-info-section {
    margin-top: 10px;
    position: relative;

    .text-highlight {
      color: @colorRed;
    }

    .flight-section-header {
      font-family: @fontCondensed;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .badge-date-section {
    display: flex;
    align-items: center;

    .badge {
      font-size: 12px;
      padding: 5px 10px;
      background-color: @colorRed;
      display: inline-block;
      border-radius: 6px;
      color: @colorWhite;
      margin-right: 6px;
      line-height: normal;
    }

    > h4 {
      margin: 0;
      color: @colorGrey11;
      font-size: 24px;
      font-family: @font6;

      > div > span:last-child {
        color: @colorRed;
      }
    }
  }

  .shipment-title {
    .shipment-info-title();
  }

  .shipment-status {
    font-size: 21px;
    font-weight: 700;
    font-family: @fontCondensed;
    color: @colorGrey3;
    margin-bottom: 6px;

    > div {
      color: @colorRed;
    }
  }
}

.flight-itinerary {
  .flight-title {
    .shipment-info-title();
  }

  .MapCard {
    height: 400px;
  }

  .vertical-separator {
    height: 45px;
    margin: 0 auto;
    background-color: @colorGrey7;
  }

  .airline-logo {
    display: flex;
    align-items: center;
    margin: 0 8px 0 4px;

    > img {
      width: 30px;
      height: 30px;
    }
  }

  + .ant-col > .ShipmentDetails {
    padding-left: 40px;
    padding-right: 0;

    .waypoint-line-wrapper {
      transform: translateX(-100%);
      left: 0;
    }
  }
}

.TwoLinesSeparator {
  margin-top: 4px;
  margin-bottom: 4px;
  &::before {
    border-top: 1px solid @colorGrey9;
    background-color: @colorWhite8;
  }
}

.waypoint-line-wrapper {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 65px;
  transform: translateX(100%);
  right: 0;

  .waypoint-icon {
    color: @colorRed;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    font-size: 20px;

    &.waypoint-icon-bottom {
      bottom: -32px;
    }

    &.waypoint-icon-top {
      top: 4px;
    }
  }

  .waypoint-line {
    background-image: url(../../../resources/img/Line.svg);
    background-repeat: no-repeat;
    bottom: -10px;
    left: 50%;
    position: absolute;
    top: 24px;
    transform: translateX(-50%);
    width: 2px;
  }

  .waypoint-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: @colorWhite;
    border: 1px solid @colorRed;
    padding: 4px;
    width: 45px;
    text-align: center;
    border-radius: 4px;
    line-height: normal;
    color: @colorRed;
    font-weight: 700;
  }
}

.quote-plane-direction {
  &-left {
    transform: rotateY(-180deg);
  }
}
